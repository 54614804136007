function eventMessageListener(event) {
    if (event.data.action === 'BANNER_SHOW') {
        const heightValue = event.data.data.height;
        const targetElement = document.getElementById('promotion-banner-slider-wrapper');

        const currentPaddingTop = parseFloat(window.getComputedStyle(targetElement).paddingTop) || 0;
        const newHeight = currentPaddingTop + heightValue;

        if (targetElement) {
            targetElement.style.paddingTop = `${newHeight}px`;
            targetElement.style.marginTop = `-${newHeight}px`;
        } 
    }

    if (event.data.action === 'BANNER_CLOSE') {
        const heightValue = event.data.data.height;
        const targetElement = document.getElementById('promotion-banner-slider-wrapper');

        const currentPaddingTop = parseFloat(window.getComputedStyle(targetElement).paddingTop) || 0;
        const newHeight = currentPaddingTop - heightValue;

        if (targetElement) {
            targetElement.style.paddingTop = `${newHeight}px`;
            targetElement.style.marginTop = `-${newHeight}px`;
        } 
    }

}

window.addEventListener('message', function (event) {
    eventMessageListener(event);
});

module.exports = { eventMessageListener }