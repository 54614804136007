import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["color", "radius"]
const _hoisted_2 = {
  key: 0,
  class: "search-hidden sm:inline-block w-full mb-2 sm:mb-search-title-bottom-margin font-search-title-font-weight text-search-title-font-size leading-search-title-line-height search-title"
}
const _hoisted_3 = {
  key: 1,
  class: "search-hidden sm:inline-block w-full mb-2 sm:mb-search-title-bottom-margin search-title"
}
const _hoisted_4 = {
  type: "h1",
  color: "container-header-on-primary"
}
const _hoisted_5 = {
  key: 0,
  class: "bg-search-icon-close w-6 h-6 bg-no-repeat bg-left"
}
const _hoisted_6 = {
  key: 1,
  color: "container-on-primary",
  size: "24",
  name: "close"
}
const _hoisted_7 = { class: "flex flex-wrap items-center justify-center sm:flex-nowrap" }
const _hoisted_8 = {
  "filter-text": "",
  class: "provider-dropdown-top-text text-filter-text"
}
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  value: ""
}
const _hoisted_11 = {
  key: 1,
  value: "",
  disabled: "true"
}
const _hoisted_12 = ["value"]
const _hoisted_13 = {
  key: 1,
  class: "bg-search-icon-magnify shrink-0 mr-2 ml-margin-input-icon-magnify search-hidden w-[20px] h-[20px] bg-no-repeat bg-left sm:block static"
}
const _hoisted_14 = {
  key: 2,
  class: "bg-search-icon-magnify shrink-0 mr-2 ml-margin-input-icon-magnify rounded-input flex w-[20px] h-[20px] bg-no-repeat bg-left static"
}
const _hoisted_15 = { class: "w-full flex items-center pl-0 mr-4 mb-0 font-normal relative" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["value", "placeholder"]
const _hoisted_18 = {
  key: 3,
  "search-stats": "",
  class: "w-full text-xs mt-4"
}
const _hoisted_19 = {
  key: 0,
  class: "text-number-of-results-text"
}
const _hoisted_20 = {
  key: 1,
  type: "p",
  size: "small-2",
  color: "container-body-on-primary"
}
const _hoisted_21 = {
  key: 4,
  "search-stats-skeleton": "",
  class: "bg-bg-placeholder rounded-checkbox w-40 h-4 animate-pulse opacity-80 mt-4"
}
const _hoisted_22 = {
  key: 0,
  class: "flex mb-2 sm:mb-0 sm:mr-2 sm:items-center text-sm font-normal leading-none"
}
const _hoisted_23 = {
  key: 1,
  class: "flex mb-2 sm:mb-0 sm:mr-2 sm:items-center"
}
const _hoisted_24 = {
  type: "label",
  color: "container-body-on-primary"
}
const _hoisted_25 = ["size"]
const _hoisted_26 = { key: 0 }
const _hoisted_27 = {
  key: 0,
  "no-results-message": "",
  class: "text-center mt-2 mb-5"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { class: "n-paragraph-1 text-no-results-header font-bold mb-3.5" }
const _hoisted_30 = {
  key: 0,
  class: "n-paragraph-2 text-no-results-message"
}
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "mb-3.5" }
const _hoisted_33 = {
  type: "h3",
  color: "container-header-on-primary"
}
const _hoisted_34 = {
  key: 0,
  type: "p",
  size: "body-1",
  color: "container-body-on-primary"
}
const _hoisted_35 = { key: 1 }
const _hoisted_36 = {
  key: 1,
  class: "grid grid-flow-col auto-cols-carousel gap-[16px]"
}
const _hoisted_37 = {
  key: 3,
  "error-message": "",
  class: "text-center mt-2"
}
const _hoisted_38 = {
  key: 0,
  class: "n-paragraph-1 text-no-results-message"
}
const _hoisted_39 = { key: 1 }
const _hoisted_40 = {
  type: "h3",
  color: "primary"
}
const _hoisted_41 = {
  key: 4,
  class: "flex items-center justify-center w-full mt-6"
}
const _hoisted_42 = ["loading", "disabled"]
const _hoisted_43 = {
  key: 1,
  "enticing-games-container": "",
  class: "mt-2"
}
const _hoisted_44 = {
  key: 0,
  "enticing-games-title": ""
}
const _hoisted_45 = {
  key: 0,
  class: "inline-block w-full text-lg text-no-results-header mb-2 sm:mb-4 font-bold"
}
const _hoisted_46 = {
  key: 1,
  class: "inline-block w-full mb-2 sm:mb-4"
}
const _hoisted_47 = {
  type: "h2",
  color: "container-header-on-primary"
}
const _hoisted_48 = {
  key: 1,
  "enticing-games-title-skeleton": "",
  class: "animate-pluse opacity-80 h-6 mb-2 sm:mb-4 w-[150px] bg-bg-placeholder rounded-checkbox"
}
const _hoisted_49 = {
  key: 0,
  class: "grid grid-flow-col auto-cols-carousel gap-[16px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClearButton = _resolveComponent("ClearButton")!
  const _component_SearchPill = _resolveComponent("SearchPill")!
  const _component_SearchResultSkeleton = _resolveComponent("SearchResultSkeleton")!
  const _component_SearchResult = _resolveComponent("SearchResult")!
  const _component_GameGrid = _resolveComponent("GameGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      "overlay-backdrop": "",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeOverlay && _ctx.closeOverlay(...args))),
      class: _normalizeClass({
        'overlay-backdrop w-screen opacity-75 fixed left-0 z-20 respect-sga-height smooth-overlay-transition': _ctx.overlayActive
    })
    }, null, 2 /* CLASS */),
    _withDirectives(_createElementVNode("div", {
      ref: "searchContainer",
      "search-container": "",
      class: _normalizeClass(["w-full smooth-overlay-transition", {
            'bg-bg-search': _ctx.isJackpotjoy,
            'lg:rounded-background': _ctx.isJackpotjoy && _ctx.overlayActive,
            'opacity-100': _ctx.overlayActive && _ctx.overlayOnly,
            'opacity-0': !_ctx.overlayActive && _ctx.overlayOnly,
            'z-20 flex-col fixed flex search-container-focus lg:max-w-[1000px]': _ctx.overlayActive,
        }])
    }, [
      _createElementVNode("spk-container", {
        color: _ctx.spkContainerColor,
        radius: _ctx.spkContainerRadius,
        overflow: "hidden",
        class: "grid h-full"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["wrapper flex flex-col h-full", {
                    'py-4 sm:py-6': !_ctx.overlayActive,
                }])
        }, [
          _createElementVNode("div", {
            ref: "searchFocusTopbar",
            "search-focus-topbar": "",
            class: _normalizeClass(["flex flex-col pb-2 px-4 sm:px-6 max-w-[990px] w-full mx-auto 2xl:max-w-[996px]", {
                        'pt-4 sm:pt-12': _ctx.overlayActive,
                        'sm:flex-nowrap rounded-background-mobile sm:rounded-background': !_ctx.overlayActive,
                    }])
          }, [
            (_ctx.isJackpotjoy && _ctx.overlayActive)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.translations.search_among_games_and_providers), 1 /* TEXT */))
              : (!_ctx.isJackpotjoy && _ctx.overlayActive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("spk-text", _hoisted_4, _toDisplayString(_ctx.translations.search_among_games_and_providers), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true),
            (_ctx.overlayActive)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  "desktop-close-button": "",
                  class: "search-hidden fixed sm:flex items-center justify-center top-0 right-0 p-6 cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeOverlay && _ctx.closeOverlay(...args)))
                }, [
                  (_ctx.isJackpotjoy)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                    : (_openBlock(), _createElementBlock("spk-icon", _hoisted_6))
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: _normalizeClass(["min-w-full sm:min-w-[8em] shrink-0 relative order-2 sm:mr-2 sm:order-1", {'sm:min-w-[8em] sm:order-1 sm:mr-2': !_ctx.overlayActive}])
              }, [
                _createElementVNode("label", {
                  class: _normalizeClass(["n-input relative w-full mb-0 px-3 sm:px-3.5 inline-flex justify-between items-center text-sm bg-filter-bg-color rounded-input border-input-border border-filter-stroke h-[44px]", {'sm:min-w-[8em] sm:order-1 sm:mr-2 sm:px-3.5': !_ctx.overlayActive}]),
                  style: {"pointer-events":"none","line-height":"20px"}
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.providerDropdownTop), 1 /* TEXT */),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "ml-2.5" }, [
                    _createElementVNode("svg", {
                      width: "8",
                      height: "14",
                      viewBox: "0 0 8 14",
                      fill: "none",
                      class: "fill-filter-icon"
                    }, [
                      _createElementVNode("path", { d: "M8 6L4 0L0 6H8Z" }),
                      _createElementVNode("path", { d: "M0 8L4 14L8 8H0Z" })
                    ])
                  ], -1 /* HOISTED */))
                ], 2 /* CLASS */),
                _withDirectives(_createElementVNode("select", {
                  ref: "providerDropdown",
                  class: "provider-dropdown absolute appearance-none left-0 top-0 w-full h-full z-5 opacity-0 cursor-pointer",
                  "provider-dropdown": "",
                  onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.provider) = $event)),
                  placeholder: _ctx.providerDropdownTop
                }, [
                  (_ctx.providers && !_ctx.isLoadingProviders)
                    ? (_openBlock(), _createElementBlock("option", _hoisted_10, _toDisplayString(_ctx.translations.all_providers), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  (_ctx.providers && !_ctx.isLoadingProviders)
                    ? (_openBlock(), _createElementBlock("option", _hoisted_11, "---------------"))
                    : _createCommentVNode("v-if", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.providers, (provider, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: provider.code
                    }, _toDisplayString(provider.name) + " (" + _toDisplayString(provider.count) + ")", 9 /* TEXT, PROPS */, _hoisted_12))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_9), [
                  [_vModelSelect, _ctx.provider]
                ])
              ], 2 /* CLASS */),
              _createElementVNode("div", {
                ref: "searchInputWrapper",
                "search-input-wrapper": "",
                class: _normalizeClass(["grow order-1 mb-2 n-input border-input-border border-input-stroke focus:border-input-stroke-active text-text--input sm:mb-0 p-0 flex flex-row justify-between items-center h-[44px] font-sparkfont rounded-input", {
                                'sm:order-2 sm:mb-0': !_ctx.overlayActive,
                                'border-input-stroke-active': _ctx.searchInputFocused,
                            }])
              }, [
                (_ctx.overlayActive)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "bg-search-icon-arrow shrink-0 mx-2 block w-6 h-6 bg-no-repeat bg-left sm:hidden cursor-pointer static",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeOverlay && _ctx.closeOverlay(...args))),
                      "focused-overlay-back": ""
                    }))
                  : _createCommentVNode("v-if", true),
                (_ctx.overlayActive)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_13))
                  : _createCommentVNode("v-if", true),
                (!_ctx.overlayActive)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("label", _hoisted_15, [
                  (_ctx.showRollingEffect)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        ref: "slotRoller",
                        innerHTML: _ctx.slotRollingEffect(),
                        class: "absolute text-slot-rolling-effect-color"
                      }, null, 8 /* PROPS */, _hoisted_16))
                    : _createCommentVNode("v-if", true),
                  _createElementVNode("input", {
                    class: "search-input w-full p-0 m-auto border-none outline-none search-input-background shadow-none text-input-color placeholder-input-placeholder-color",
                    "search-input": "",
                    type: "text",
                    ref: "searchInput",
                    value: _ctx.searchString,
                    onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args))),
                    onFocus: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onFocusInputfield && _ctx.onFocusInputfield(...args))),
                    onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlurInputfield && _ctx.onBlurInputfield(...args))),
                    onKeyup: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args))),
                    autocomplete: "off",
                    placeholder: _ctx.isLoadingProviders || !_ctx.searchableGamesCount || _ctx.showRollingEffect ? '' : _ctx.t(_ctx.translations.input_placeholder, {
                                        total: _ctx.searchableGamesCount.toString()
                                    })
                  }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_17)
                ]),
                _withDirectives(_createVNode(_component_ClearButton, {
                  ref: "clearButton",
                  translation: _ctx.translations.clear || '',
                  "webfront-params": _ctx.webfrontParams,
                  onClick: _ctx.clearSearchString,
                  class: "text-input-icon-x"
                }, null, 8 /* PROPS */, ["translation", "webfront-params", "onClick"]), [
                  [_vShow, _ctx.searchIsActive]
                ])
              ], 2 /* CLASS */)
            ]),
            (!_ctx.isLoading && _ctx.hasGameResults)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  (_ctx.isJackpotjoy)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.t(_ctx.translations.showing_x_of_x_results, {
                                displayed: _ctx.numberOfDisplayedResults,
                                total: _ctx.numberOfTotalResults
                            })), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("spk-text", _hoisted_20, _toDisplayString(_ctx.t(_ctx.translations.showing_x_of_x_results, {
                                displayed: _ctx.numberOfDisplayedResults,
                                total: _ctx.numberOfTotalResults
                            })), 1 /* TEXT */))
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */),
          (!_ctx.searchPillsEmpty)
            ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                ref: "searchPillsParentWrapper",
                "search-pills-parent-wrapper": "",
                class: _normalizeClass(["w-full px-4 sm:px-6 max-w-[990px] mx-auto 2xl:max-w-[996px] mt-2 sm:flex", {
                        'mb-2': _ctx.overlayActive,
                    }])
              }, [
                (_ctx.isJackpotjoy)
                  ? (_openBlock(), _createElementBlock("label", _hoisted_22, _toDisplayString(_ctx.searchPillsText), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createElementVNode("spk-text", _hoisted_24, _toDisplayString(_ctx.searchPillsText), 1 /* TEXT */)
                    ])),
                _createElementVNode("div", {
                  ref: "searchPillsWrapper",
                  class: _normalizeClass({'mt-2 sm:mt-0': _ctx.overlayActive})
                }, [
                  _createElementVNode("spk-segmented-button", {
                    align: "left",
                    wrap: "",
                    size: _ctx.getSpkPillSize()
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchPills, (searchPill, index) => {
                      return (_openBlock(), _createBlock(_component_SearchPill, {
                        onClick: ($event: any) => (_ctx.onSearchPillClick(searchPill, index)),
                        key: index,
                        name: searchPill
                      }, null, 8 /* PROPS */, ["onClick", "name"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 8 /* PROPS */, _hoisted_25)
                ], 2 /* CLASS */)
              ], 2 /* CLASS */)), [
                [_vShow, !_ctx.searchIsActive && !_ctx.provider]
              ])
            : _createCommentVNode("v-if", true),
          _withDirectives(_createElementVNode("div", {
            ref: "searchResultsWrapper",
            "search-results-wrapper": "",
            class: _normalizeClass(["px-4 sm:px-6 py-4 pt-search-result-padding-top max-w-[990px] w-full mx-auto 2xl:max-w-[996px]", {
                        'overflow-y-auto': _ctx.overlayActive,
                        'overflow-hidden': !_ctx.overlayActive,
                    }])
          }, [
            (_ctx.hasEnoughDataToSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  (_ctx.noResultsFound)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        (_ctx.isJackpotjoy)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                              _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.t(_ctx.translations.no_results, {
                                    searchString: _ctx.searchString
                                })), 1 /* TEXT */),
                              (_ctx.noResultsOrPopularFound)
                                ? (_openBlock(), _createElementBlock("p", _hoisted_30, _toDisplayString(_ctx.translations.try_these), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              _createElementVNode("div", _hoisted_32, [
                                _createElementVNode("spk-text", _hoisted_33, _toDisplayString(_ctx.t(_ctx.translations.no_results, {
                                        searchString: _ctx.searchString
                                    })), 1 /* TEXT */)
                              ]),
                              _createElementVNode("div", null, [
                                (_ctx.noResultsOrPopularFound)
                                  ? (_openBlock(), _createElementBlock("spk-text", _hoisted_34, _toDisplayString(_ctx.translations.try_these), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true)
                              ])
                            ]))
                      ]))
                    : _createCommentVNode("v-if", true),
                  (_ctx.noResultsFound)
                    ? (_openBlock(), _createElementBlock("game-carousel", _hoisted_35, [
                        (_ctx.isLoading)
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options.pageSize, (index) => {
                              return (_openBlock(), _createBlock(_component_SearchResultSkeleton, { key: index }))
                            }), 128 /* KEYED_FRAGMENT */))
                          : (_openBlock(), _createElementBlock("div", _hoisted_36, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gamesArray, (game, index) => {
                                return (_openBlock(), _createBlock(_component_SearchResult, {
                                  key: game.url_alias,
                                  "search-result": game,
                                  position: index,
                                  "launch-origin": _ctx.noResultsOrPopularFound ? 'search/popular/no-games-found':'search'
                                }, null, 8 /* PROPS */, ["search-result", "position", "launch-origin"]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ]))
                      ]))
                    : (_openBlock(), _createBlock(_component_GameGrid, {
                        key: 2,
                        "search-results-grid": ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gamesArray, (game, index) => {
                            return (_openBlock(), _createBlock(_component_SearchResult, {
                              key: game.url_alias,
                              "search-result": game,
                              position: index,
                              "launch-origin": _ctx.noResultsOrPopularFound ? 'search/popular/no-games-found':'search'
                            }, null, 8 /* PROPS */, ["search-result", "position", "launch-origin"]))
                          }), 128 /* KEYED_FRAGMENT */)),
                          (_ctx.isLoading)
                            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.options.pageSize, (index) => {
                                return (_openBlock(), _createBlock(_component_SearchResultSkeleton, { key: index }))
                              }), 128 /* KEYED_FRAGMENT */))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      })),
                  (_ctx.displayErrorMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                        (_ctx.isJackpotjoy)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_38, _toDisplayString(_ctx.translations.search_failed), 1 /* TEXT */))
                          : (_openBlock(), _createElementBlock("div", _hoisted_39, [
                              _createElementVNode("spk-text", _hoisted_40, _toDisplayString(_ctx.translations.search_failed), 1 /* TEXT */)
                            ]))
                      ]))
                    : _createCommentVNode("v-if", true),
                  (_ctx.hasGameResults && _ctx.canLoadMore && !_ctx.displayErrorMessage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                        _createElementVNode("spk-button", {
                          "load-more-button": "",
                          loading: _ctx.isLoading,
                          color: "tertiary",
                          disabled: _ctx.isLoading,
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onLoadMore()))
                        }, _toDisplayString(_ctx.translations.load_more), 9 /* TEXT, PROPS */, _hoisted_42)
                      ]))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.overlayActive && !_ctx.hasEnoughDataToSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                  (!_ctx.isLoadingEnticingGames)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                        (_ctx.isJackpotjoy)
                          ? (_openBlock(), _createElementBlock("h2", _hoisted_45, _toDisplayString(this.recentlyPlayedGames?.recently_played?.length ? this.translations.recently_played_games : this.translations.popular_games), 1 /* TEXT */))
                          : (_openBlock(), _createElementBlock("div", _hoisted_46, [
                              _createElementVNode("spk-text", _hoisted_47, _toDisplayString(this.recentlyPlayedGames?.recently_played?.length ? this.translations.recently_played_games : this.translations.popular_games), 1 /* TEXT */)
                            ]))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_48)),
                  _createElementVNode("game-carousel", null, [
                    (!_ctx.isLoadingEnticingGames)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.recentlyPlayedGames?.recently_played?.length ? _ctx.recentlyPlayedGames?.recently_played : _ctx.popularGames?.popular), (game, index) => {
                            return (_openBlock(), _createBlock(_component_SearchResult, {
                              key: game.url_alias,
                              "search-result": game,
                              position: index,
                              "launch-origin": _ctx.recentlyPlayedGames?.recently_played?.length ? 'search/recently-played' : 'search/popular'
                            }, null, 8 /* PROPS */, ["search-result", "position", "launch-origin"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]))
                      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.options.pageSize, (index) => {
                          return (_openBlock(), _createBlock(_component_SearchResultSkeleton, { key: index }))
                        }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */), [
            [_vShow, _ctx.overlayActive || _ctx.hasEnoughDataToSearch]
          ])
        ], 2 /* CLASS */)
      ], 8 /* PROPS */, _hoisted_1)
    ], 2 /* CLASS */), [
      [_vShow, _ctx.overlayActive || _ctx.showSearch]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}